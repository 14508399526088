<template>
  <div class="animated fadeIn">

    <!-- THIS MODAL IS TO SEE THE MASTER INVOICE INVOICES -->
    <b-modal id="master-invoice-modal" ref="master-invoice-modal" class="modal-dialog" hide-header ok-only>
      <b-card>
        <!-- <rotate-square2 id="loading-gif" v-show="loadingInvoiceData"></rotate-square2> -->
        <div class="row">
          <div class="col-md-12">
            <Master-Invoice v-bind:propafes="this.afes" v-bind:propinvoice="this.invoice" v-bind:onlyInvoice='false'>
            </Master-Invoice>
          </div>
        </div>
      </b-card>
    </b-modal>
    <!-- This modal is to view a loaded invoice -->
    <b-modal id="invoice-modal" :no-close-on-backdrop="showModal" size="xl" ref="master-invoice-modal"
      class="modal-dialog" hide-header @ok="submitAfeData()" ok-only>
      <b-card>
        <div class="row">
          <div class="col-md-12">
            <MAN-Invoice v-bind:propinvoice="this.invoice" v-bind:removeQbyteInvoice="true" v-bind:propafes="this.afes"
              v-on:editedAfe="toggleSubmit($event)"></MAN-Invoice>
          </div>
        </div>
      </b-card>
    </b-modal>

    <b-card class="v-select-overflow">
      <InvoiceHeader v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:serviceData="serviceData"
        v-bind:afes="afes" v-bind:currency="currency" v-bind:tax="tax" v-bind:company_name="company_name">
      </InvoiceHeader>

      <div class="mt-2 invoice-status">
        <div v-if="invoice.accountant_paid === 0">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> AP Team Review</button>
        </div>
        <div v-if="invoice.accountant_paid === 1">
          <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
        </div>
        <div v-if="invoice.qbyte_status != null">
          <button v-if="invoice.qbyte_status.code == 0" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-secondary"><i class="fa fa-question"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == -2" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-secondary"><i class="fa fa-question"></i>Unprocessed</button>
          <button v-if="invoice.qbyte_status.code == 1" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-blue"><i class="fa fa-save"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == 2" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-primary"><i class="fa fa-check"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == 3" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-warning"><i class="fa fa-question"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == 4" style="text-transform: capitalize"
            class="btn-sm mt-2 btn-success"><i class="fa fa-check"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == -3"
            style="text-transform: capitalize; background-color: dimgray; color: white;" class="btn-sm mt-2"><i
              class="fa fa-trash"></i> {{ invoice.qbyte_status.text }}</button>
          <button v-if="invoice.qbyte_status.code == -5"
            style="text-transform: capitalize; background-color: crimson; color: white;" class="btn-sm mt-2"><i
              class="fa fa-question"></i> Accountant Deleted</button>
        </div>
      </div>

      <div class="mb-4">
        <b-button v-b-modal.invoice-modal class="pull-right pl-3 btn-sm font-weight-bold" variant="primary"><span
            class="mt-2 d-none d-sm-inline mr-1">View Invoice </span><i class="fas fa-search"></i></b-button>
        <b-button v-b-modal.master-invoice-modal class="pull-right pl-3 btn-sm font-weight-bold mr-2" variant="primary">
          <span class="mt-2 d-none d-sm-inline mr-1">View Master Invoice </span><i class="fas fa-search"></i>
        </b-button>
      </div>
      <br />
      <b-row class="mt-3 clearfix mb-4">
        <b-col>
          <VendorDetails v-bind:invoice="invoice"></VendorDetails>
        </b-col>
        <b-col>
          <CompanyDetails v-bind:invoice="invoice" v-bind:company_name="company_name"></CompanyDetails>
        </b-col>
      </b-row>
      <b-row>
        <InvoiceDetails v-bind:invoice="invoice" v-bind:highestAfe="highestAfe" v-bind:toggleVselect="false"
          v-bind:toggleEditVIN="false"></InvoiceDetails>
      </b-row>

      <h2>Details</h2 v-show="invoiceConfigLoading">
      <RotateSquare2 class="loading-gif" v-show="!invoiceConfigLoading"></RotateSquare2>
      <div class="details-box" style="background-color: rgb(242, 242, 242);" v-show="invoiceConfigLoading">
        <div class="row">
          <div class="col-12 col-lg-6">Organization:
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.org" label="value_1" :options="org_idOptions">
              <template v-slot:option="option">
                <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
              </template>
              <template #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                <span v-if="value_1 != null">{{ value_1 }}</span>
                <span v-if="value_2 != null">-{{ value_2 }}</span>
                <span v-if="value_3 != null">-{{ value_3 }}</span>
                <span v-if="value_4 != null">-{{ value_4 }}</span>
              </template>
            </v-select>
          </div>
          <div class="col-12 col-lg-6">Invoice Type:
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.invoice_type" label="value_1" class="mt-1" :options="invoice_typeOptions">
              <template v-slot:option="option">
                <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
              </template>
              <template #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                <span v-if="value_1 != null">{{ value_1 }}</span>
                <span v-if="value_2 != null">-{{ value_2 }}</span>
                <span v-if="value_3 != null">-{{ value_3 }}</span>
                <span v-if="value_4 != null">-{{ value_4 }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">Bus Assoc: <span
              v-if="lodash.has(invoiceConfig.business_associate, 'is_exact_match') && lodash.has(invoiceConfig.business_associate, 'is_exact_match') && !invoiceConfig.business_associate.is_exact_match"
              class="error-match"><i class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.business_associate" label="value_1" class="mt-1"
              :options="invoice_businessAssociateOptions">
              <template v-slot:option="option">
                <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
              </template>
              <template #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                <span v-if="value_1 != null">{{ value_1 }}</span>
                <span v-if="value_2 != null">-{{ value_2 }}</span>
                <span v-if="value_3 != null">-{{ value_3 }}</span>
                <span v-if="value_4 != null">-{{ value_4 }}</span>
              </template>
            </v-select>
          </div>
          <div class="col-12 col-lg-6">Address: <input type="text" class="detail-box-input" readonly
              :value="invoice.addressline1 + ' ' + invoice.addressline2"></div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6">Sep. Cheque:
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="defaultUnchecked" onclick="return false"
                    v-if="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                    v-model="invoiceConfig.is_separate_cheque">
                  <input type="checkbox" class="custom-control-input" id="defaultUnchecked" v-else
                    v-model="invoiceConfig.is_separate_cheque">
                  <label class="custom-control-label" for="defaultUnchecked">Yes</label>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-6">Is Quickpay:
                <div class="custom-control custom-checkbox" v-if="invoiceConfig.has_quickpay">
                  <input type="checkbox" class="custom-control-input" id="defaultUnchecked2"
                    :checked="invoiceConfig.has_quickpay"
                    :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" @click="isQuickPay()">
                  <label class="custom-control-label" for="defaultUnchecked2">Yes</label>
                </div>
                <div class="custom-control custom-checkbox" v-else>
                  <input type="checkbox" class="custom-control-input" disabled id="defaultUnchecked2">
                  <label class="custom-control-label" for="defaultUnchecked2">No</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3">Created By: <input type="text" class="detail-box-input" readonly
              :value="profile.fname + ' ' + profile.lname"></div>
          <div class="col-12 col-sm-6 col-md-3">Exchange Rate: <input class="detail-box-input"
              :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" type="number" step="any"
              v-model="invoiceConfig.exchange_rate"></div>
          <div class="col-12 col-sm-6 col-md-3">Period:
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.accounting_period" label="line" class="mt-1" :options="invoice_periodOptions">
              <template v-slot:option="option">
                <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                <span v-if="option.value_2 != null">-{{ $moment(option.value_2).format("YYYY/MM") }}</span>
                <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
              </template>
              <template #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                <span v-if="value_1 != null">{{ value_1 }}</span>
                <span v-if="value_2 != null">-{{ $moment(value_2).format("YYYY/MM") }}</span>
                <span v-if="value_3 != null">-{{ value_3 }}</span>
                <span v-if="value_4 != null">-{{ value_4 }}</span>
              </template>
            </v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">Pay Code: <i v-b-tooltip.hover title="Remove"
              class="hover-mouse fa fa-times-circle"
              v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code) && invoiceConfig.pay_code != null && invoiceConfig.pay_code != ''"
              @click="invoiceConfig.pay_code = null"></i>
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.pay_code" label="value_1" class="mt-1" :options="pay_codeOptions"></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-3">PO/PA#:
            <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
              v-model="invoiceConfig.purchase_num" label="value_1" class="mt-1" :options="purchase_numOptions">
            </v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-3">Date: <input type="text" class="detail-box-input"
              v-b-popover="'YYYY/MM/DD'" title="Input Date Format" v-model="invoiceConfig.invoice_date"></div>
          <div class="col-12 col-sm-6 col-md-3">Vendor Invoice Number:
            <b-input-group size="md" class="mb-3">
              <b-form-input type="text" style="background-color: white;" :readonly="isEditInvoice"
                v-model="invoiceConfig.vendor_invoice_num"></b-form-input>
              <b-input-group-append>
                <b-button size="md" style="margin-top: 5px;" v-if="!isEditInvoice"
                  @click="isEditInvoice = !isEditInvoice; invoiceConfig.vendor_invoice_num = oldVin" text="Button"
                  variant="danger">X</b-button>
                <b-button size="md" style="margin-top: 5px;" text="Button" v-if="!isEditInvoice" variant="success"
                  @click="editVendorInvoiceNumber()">&#x2714;</b-button>
                <b-button size="md" style="margin-top: 5px;" text="Button" v-if="isEditInvoice" variant="primary"
                  :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" @click="
  isEditInvoice = !isEditInvoice;
                  "><i class="fa fa-edit"></i></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

        </div>

        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">Discount Percentage %:<input readonly class="detail-box-input"
              type="number" step="any" v-model="discount_percentage" @input="changeDiscount(invoiceConfig.subtotal)">
          </div>
          <div class="col-12 col-sm-6 col-md-3">Discount Amount:<input readonly class="detail-box-input" type="number"
              step="any" v-model="discount_amount" @input="changeAmount(invoiceConfig.subtotal)"></div>
          <div class="col-12 col-sm-6 col-md-3">Hold Date
            <!-- <i class="fa fa-times" v-if="[0,1,-1,-2,-3].includes(invoice.qbyte_status.code) && invoiceConfig.hold_date != null && invoiceConfig.hold_date != ''" @click="invoiceConfig.hold_date = ''"></i> -->
            <v-date-picker v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)" class="vc-calendar"
              :formats='formats' v-model='invoiceConfig.hold_date' show-caps>
            </v-date-picker>
            <input type="text" readonly v-model='invoiceConfig.hold_date'
              v-if="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)">
          </div>
          <div class="col-12 col-sm-6 col-md-3">Due. Date:
            <!-- <i class="fa fa-times" v-if="[0,1,-1,-2,-3].includes(invoice.qbyte_status.code) && invoiceConfig.due_date != null && invoiceConfig.due_date != ''" @click="invoiceConfig.due_date = null"></i> -->
            <v-date-picker v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)" class="vc-calendar"
              :formats='formats' v-model='invoiceConfig.due_date' show-caps>
            </v-date-picker>
            <input type="text" readonly v-model='invoiceConfig.due_date'
              v-if="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)">
          </div>
        </div>
      </div>

      <h2 class="mt-4" v-show="invoiceConfigLoading">Taxes</h2>

      <div v-if="invoiceConfig" v-show="invoiceConfigLoading">
        <div class="row mb-3 text-center d-none d-xl-flex">
          <div class="col-xl-1" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
            &nbsp;
          </div>
          <div class="col-xl-2">
            <strong>Tax Name</strong>
          </div>
          <div class="col-xl-2" v-show="false">
            <strong>Tax %</strong>
          </div>
          <div class="col-xl-1">
            <strong>Amount</strong>
          </div>
          <div class="col-xl-3">
            <strong style="margin-right:51px;"><span v-if="invoiceConfig.is_quickpay">Quickpay Tax
                Amount</span></strong>
          </div>
          <div class="col-xl-2">
            <strong>Cost Code</strong>
          </div>
        </div>
        <!-- //taxessss -->
        <div class="row mb-2" v-for="(line, index) in taxes" id="taxes">
          <div class="col-xl-1 text-center" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
            <h6 class="mt-2 d-block d-xl-none">Remove</h6>
            <i @click="taxes.splice(index, 1)" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"
              v-b-tooltip.hover title="Remove" style="color: #f86c6b; font-weight: bold"
              class="fa fa-minus-circle mt-2"></i>
          </div>
          <div class="col-xl-2">
            <h6 class="mt-2 d-block d-xl-none">Tax Name</h6>
            <input type="text" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" class="form-control"
              placeholder="Name" v-model="line.name">
          </div>
          <div class="col-xl-2" v-show="false">
            <h6 class="mt-2 d-block d-xl-none">Tax %</h6>
            <input type="number" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" class="form-control"
              v-model="line.percentage" @input="setAmount(line, line.percentage, subtotal, index, quickpay_subtotal)"
              step="any" max="100" placeholder="%">
          </div>
          <div class="col-xl-3">
            <h6 class="mt-2 d-block d-xl-none"> Tax Amount</h6>
            <div class="row">
              <div :class="{ 'col-6': invoiceConfig.is_quickpay, 'col-12': !invoiceConfig.is_quickpay }">
                <input type="text" class="form-control" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  placeholder="Amount" v-model="line.amount"
                  @input="setTaxPercent(line, line.amount, subtotal, index, quickpay_subtotal)">
              </div>
              <div v-if="invoiceConfig.is_quickpay" class="col-6">
                <h6 class="mt-2 d-block d-xl-none"><span v-if="invoiceConfig.is_quickpay">QuickPay Tax Amount</span>
                </h6>
                <input type="text" readonly class="form-control" placeholder="Amount"
                  v-if="line.quickpay_subtotal != undefined" v-model="line.quickpay_amount">
                <input type="text" readonly class="form-control" placeholder="Amount" v-else
                  v-model="line.quickpay_amount">
              </div>
            </div>
          </div>
          <div
            :class="{ 'col-xl-6': [0, 1, -1, -2, -3].includes(invoice.qbyte_status.code), 'col-xl-6': [2, 3, 4, 5, -5].includes(invoice.qbyte_status.code) }">
            <h6 class="mt-2 d-block d-xl-none">Cost Code</h6>
            <div class="row">
              <div class="col-1 text-right">
                <!-- <i v-b-tooltip.hover title="Remove" class="hover-mouse mt-2 fa fa-times-circle mr-2" @click="line.account = null"></i> -->
              </div>
              <div
                :class="{ 'col-11': [0, 1, -1, -2, -3].includes(invoice.qbyte_status.code), 'col-12': [2, 3, 4, 5, -5].includes(invoice.qbyte_status.code) }">
                <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  :options="paginated" :filterable="false" label="line" v-model="line.account" @open="onOpen"
                  @close="onClose" @search="query => search = query">
                  <template #list-footer>
                    <li ref="load" class="loader">
                      Loading more options...
                    </li>
                  </template>
                  <!-- <template v-slot:option="option">
                      <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                      <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                      <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                      <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                    </template> -->
                  <template #selected-option="{ value_1, value_2, value_3, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <!-- <span v-if="value_4 != null">-{{ value_4 }}</span> -->
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <button class="btn btn-success mt-2 mb-4" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"
          @click='taxes.push({ "name": "", "percentage": 0.0000, "amount": 0, "code": paginated[0] })'>Add New Line <i
            class="fa fa-plus"></i></button>
      </div>

      <h2 class="mt-4 clearfix" v-show="invoiceConfigLoading">AFE'S</h2>
      <div v-show="invoiceConfigLoading" class="table-responsive-sm mt-2">
        <table class="table no-bg afenum mb-0" style="border-top: 3px solid #000" v-for="(line, aIndex) in chains"
          :key="line.id">
          <thead class="rt-border">
            <tr class="afe-rows">
              <th class="center" style="width: 600px;"><strong>AFE / PO</strong></th>
              <th class="center" style="width: 600px;">Cost Center</th>
              <th class="center" style="width: 400px;"><strong>Cost Code</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="width: 600px;">
                <div class="input-group mt-2">
                  <input type="text" readonly class="form-control" :value="line.afe_num">
                  <div class="input-group-append">
                    <span class="input-group-text">DESC <i class="ml-2 fa fa-question-circle" v-b-tooltip.hover
                        :title="line.afe_desc"></i></span>
                    <span class="input-group-text">UWI <i class="ml-2 fa fa-question-circle" v-b-tooltip.hover
                        :title="line.well_uwi"></i></span>
                  </div>
                </div>
              </td>
              <td style="width: 600px">
                <div class="input-group mt-2">
                  <input type="text" readonly class="form-control" :value="line.cost_center_num">
                  <div class="input-group-append">
                    <span class="input-group-text">{{ line.cost_center_percentage }}%</span>
                  </div>
                </div>
              </td>
              <td style="width: 400px;">
                {{ line.ccode_one }} - {{ line.ccode_two }} - <span
                  v-if="typeof (line.ccode_three) == 'string' && !line.ccode_three.includes('---')">{{ line.ccode_three
                  }}</span>
              </td>
            </tr>
            <tr>
              <td style="width: 600px;" v-if="line.account && line.account.value_5 != 'N'">
                <strong>Remote AFE</strong> <span
                  v-if="line.remote_afe == null || lodash.has(line.remote_afe, 'is_exact_match') && !line.remote_afe.is_exact_match"
                  class="error-match"><i class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
                <v-select :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)" v-model="line.remote_afe"
                  :ref="'remote_afe_' + aIndex" :clearable="true" label="value_1" class="mt-2 clear-allow"
                  :options="invoice_afeOptions">

                  <template v-slot:option="option">
                    <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                    <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                    <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                    <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                  </template>
                  <template
                    #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <span v-if="value_4 != null">-{{ value_4 }}</span>
                  </template>
                </v-select>
              </td>

              <td style="width: 600px;" v-else>
                <strong>Remote AFE</strong> <span
                  v-if="line.remote_afe == null || lodash.has(line.remote_afe, 'is_exact_match') && !line.remote_afe.is_exact_match"
                  class="error-match"><i class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
                <v-select :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  v-model="remote_afe_obj.value_1" :ref="'remote_afe_' + aIndex" :clearable="true" label="value_1"
                  class="mt-2 clear-allow" :options="invoice_afeOptions">

                  <template v-slot:option="option">
                    <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                    <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                    <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                    <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                  </template>
                  <template
                    #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <span v-if="value_4 != null">-{{ value_4 }}</span>
                  </template>
                </v-select>
              </td>
              <td style="width: 600px;">
                <strong>Remote Cost Center</strong> <span class="error-match"
                  v-if="line.remote_cost_center == null || lodash.has(line.remote_cost_center, 'is_exact_match') && !line.remote_cost_center.is_exact_match"><i
                    class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
                <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  v-model="line.remote_cost_center" label="value_1" class="mt-2" :options="invoice_costCenterOptions">
                  <template v-slot:option="option">

                    <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                    <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                    <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                    <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                  </template>
                  <template
                    #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <span v-if="value_4 != null">-{{ value_4 }}</span>
                  </template>
                </v-select>
              </td>
              <td>
                <strong>Remote Cost Code</strong> <span
                  v-if="line.account == null || lodash.has(line.account, 'is_exact_match') && !line.account.is_exact_match"
                  class="error-match"><i class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
                <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  :options="paginated" class="mt-2" :filterable="false" label="line" v-model="line.account"
                  @open="onOpen" @close="onClose" @search="query => search = query">
                  <template #list-footer>
                    <li ref="load" class="loader">
                      Loading more options...
                    </li>
                  </template>
                  <!-- <template v-slot:option="option">
                      <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                      <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                      <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                      <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                    </template> -->
                  <template #selected-option="{ value_1, value_2, value_3, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <!-- <span v-if="value_4 != null">-{{ value_4 }}</span> -->
                  </template>
                </v-select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Organization</strong> <span
                  v-if="line.org == null || lodash.has(line.org, 'is_exact_match') && !line.org.is_exact_match"
                  class="error-match"><i class="fa fa-exclamation-triangle"></i>This is not an exact match.</span>
                <v-select :clearable="false" :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  v-model="line.org" label="value_1" class="mt-2" :options="org_idOptions">
                  <template v-slot:option="option">
                    <span v-if="option.value_1 != null">{{ option.value_1 }}</span>
                    <span v-if="option.value_2 != null">-{{ option.value_2 }}</span>
                    <span v-if="option.value_3 != null">-{{ option.value_3 }}</span>
                    <span v-if="option.value_4 != null">-{{ option.value_4 }}</span>
                  </template>
                  <template
                    #selected-option="{ value_1, value_2, value_3, value_4, option, deselect, multiple, disabled }">
                    <span v-if="value_1 != null">{{ value_1 }}</span>
                    <span v-if="value_2 != null">-{{ value_2 }}</span>
                    <span v-if="value_3 != null">-{{ value_3 }}</span>
                    <span v-if="value_4 != null">-{{ value_4 }}</span>
                  </template>
                </v-select>
              </td>
              <td>
                <strong>Activity Period:</strong>
                <v-select :disabled="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                  v-model="line.activity_period" label="value_1" class="mt-2 clear-allow" :clearable="true"
                  :options="afePeriodOptions">
                  <template v-slot:option="option">
                    {{ $moment(option.value_1).format("YYYY/MM") }}
                  </template>
                  <template #selected-option="{ value_1, option, deselect, multiple, disabled }">
                    {{ $moment(value_1).format("YYYY/MM") }}
                  </template>
                </v-select>
              </td>
              <td colspan="2">
                <strong>Remarks:</strong>
                <div class="input-group mt-2">
                  <input type="text" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
                    v-model="line.remark" maxlength="72" placeholder="Remarks" class="form-control">
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <strong>Subtotal</strong></br>
                    <input type="text" class="mt-2 form-control" v-model="line.subtotal" readonly
                      @input="changeSubtotal(line.subtotal, aIndex)">
                  </div>
                  <div class="col-12 col-md-4">
                    <strong>Discount ({{ discount_percentage }}%)</strong></br>
                    <input type="text" class="form-control mt-2" readonly
                      :value="'$' + formatPrice(line.subtotal * (discount_percentage / 100))">
                  </div>
                  <div class="col-12 col-md-4">
                    <strong>Discounted Total</strong></br>
                    <input type="text" class="form-control mt-2" readonly
                      :value="'$' + formatPrice(line.subtotal - (line.subtotal * (discount_percentage / 100)))">
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="6">
                <div class="row">
                  <div class="col-12 col-md-4" v-if="invoiceConfig.is_quickpay">
                    <strong>Quickpay Subtotal</strong></br>
                    <input type="text" class="mt-2 form-control" readonly
                      :value="'$' + formatPrice(line.quickpay_subtotal)">
                  </div>
                  <div class="col-12 col-md-4" v-if="invoiceConfig.is_quickpay">
                    <strong>Quickpay Discount ({{ discount_percentage }}%)</strong></br>
                    <input type="text" class="form-control mt-2" readonly
                      :value="'$' + formatPrice(line.quickpay_subtotal * (discount_percentage / 100))">
                  </div>
                  <div class="col-12 col-md-4" v-if="invoiceConfig.is_quickpay">
                    <strong>Discounted Quickpay Total</strong></br>
                    <input type="text" class="form-control mt-2" readonly
                      :value="'$' + formatPrice(line.quickpay_subtotal - (line.quickpay_subtotal * (discount_percentage / 100)))">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-row v-show="invoiceConfigLoading">
        <b-col class="col-12 col-lg-6 ml-auto">
          <h2>Totals</h2>
          <table class="table table-clear mt-1 total-table">
            <tbody>
              <tr>
                <td class="left"><strong>Original Subtotal</strong></td>
                <td class="right">${{ formatPrice(invoiceConfig.subtotal) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Discounts</strong></td>
                <td class="right">${{ formatPrice(roundOff(Number(invoiceConfig.subtotal)) - roundOff(subtotal)) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Subtotal</strong></td>
                <td class="right">${{ formatPrice(subtotal) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Taxes</strong></td>
                <td class="right">${{ formatPrice(tax) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Total</strong></td>
                <td class="right"><strong>${{ formatPrice(roundOff(subtotal) + roundOff(tax)) }} CAD</span></strong>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="col-12 col-lg-6 ml-auto" v-if="invoiceConfig.is_quickpay">
          <h2>Quickpay Totals (End Date: {{ invoice.endtday | moment("MM/DD/YYYY") }})</h2>
          <table class="table table-clear mt-1 total-table">
            <tbody>
              <tr>
                <td class="left"><strong>Original Quickpay Subtotal</strong></td>
                <td class="right">${{ formatPrice(invoiceConfig.quickpay.quickpay_subtotal) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Discounts</strong></td>
                <td class="right">${{ formatPrice(roundOff(Number(invoiceConfig.quickpay.quickpay_subtotal) -
                    roundOff(quickpay_subtotal)))
                }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Quickpay Subtotal</strong></td>
                <td class="right">${{ formatPrice(quickpay_subtotal) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Taxes</strong></td>
                <td class="right">${{ formatPrice(quickpay_tax) }}</td>
              </tr>
              <tr>
                <td class="left"><strong>Quickpay Total</strong></td>
                <td class="right"><strong>${{ formatPrice(roundOff(quickpay_subtotal) + roundOff(quickpay_tax)) }}
                    CAD</span></strong></td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <!--volumesss  -->

      <div class="row mb-3 text-center d-none d-xl-flex" v-if="vendor_service_data.length > 0" style="margin-top:35px;">
        <div class="col-xl-1">
          &nbsp;
        </div>
        <div class="col-xl-2">
          <strong>Service Name</strong>
        </div>
        <div class="col-xl-3">
          <strong>Invoice Quantity</strong>
        </div>
        <div class="col-xl-2">
          <strong>Qbyte Quantity</strong>
        </div>
        <div class="col-xl-2" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
          <strong>Code Name</strong>
        </div>
        <div class="col-xl-3" v-else>
          <strong>Code Name</strong>
        </div>

      </div>

      <div class="row mb-2" v-show="invoiceConfigLoading" v-for="(v, i) in vendor_service_data">

        <div class="col-xl-1 text-center"
          v-if="v.qbyte_service_del_status == 0 && [0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
          <h6 class="mt-2 d-block d-xl-none">Remove</h6>
          <i @click="deleted_service.push(v.vsid); vendor_service_data.splice(i, 1)"
            style="color: #f86c6b; font-weight: bold" class="fa fa-minus-circle mt-2"></i>
        </div>
        <div class="col-xl-3" v-if="v.qbyte_service_del_status == 0">
          <h6 class="mt-2 d-block d-xl-none">Service Name:</h6>
          <input type="text" readonly :value="v.name" class="form-control" placeholder="Volume">
        </div>


        <div class="col-xl-2"
          v-if="v.qbyte_service_del_status == 0 && [0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
          <h6 class="mt-2 d-block d-xl-none">Invoice Quantity: </h6>
          <b-input-group>
            <b-form-input :value="v.quantity" readonly></b-form-input>
            <template #append>
              <b-dropdown variant="outline-primary" :text="v.uom">

                <b-dropdown-item v-for="(uom, j) in uom_options"><span @click="v.uom = uom.value">{{ uom.value }}({{
                    uom.text
                }})</span></b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>
        </div>

        <div class="col-xl-3"
          v-if="v.qbyte_service_del_status == 0 && [2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)">
          <h6 class="mt-2 d-block d-xl-none" style="margin-left:165px;">Invoice Quantity: </h6>
          <input type="text" readonly :value="v.quantity" class="form-control">
        </div>

        <div class="col-xl-2"
          v-if="v.qbyte_service_del_status == 0 && [0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
          <h6 class="mt-2 d-block d-xl-none"> Qbyte Quantity:</h6>
          <b-input-group>
            <b-form-input v-model="v.qbyte_quantity"></b-form-input>
            <template #append>
              <b-dropdown variant="outline-primary" :text="v.qbyte_uom">
                <b-dropdown-item v-for="(uom, j) in uom_conversion"><span @click="v.qbyte_uom = uom.value">{{ uom.value
                }}</span></b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>

        </div>

        <div class="col-xl-3"
          v-if="v.qbyte_service_del_status == 0 && [2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)">
          <h6 class="mt-2 d-block d-xl-none" style="margin-left:71px;">Qbyte Quantity: </h6>
          <b-form-input :value="v.qbyte_quantity + ' ' + v.qbyte_uom" readonly v-if="v.prod_name != null"
            class="form-control"></b-form-input>
          <b-form-input :value="v.qbyte_quantity + ' ' + v.qbyte_uom" readonly v-else class="form-control">
          </b-form-input>
        </div>

        <div class="col-xl-3" v-if="v.qbyte_service_del_status == 0">
          <h6 class="mt-2 d-block d-xl-none" style="margin-left:105px;">Code Name:</h6>
          <input type="text" readonly :value="v.prod_name" class="form-control">

        </div>

      </div>

      <b-row v-show="invoiceConfigLoading">
        <b-col lg="4" class="mt-4 col-12 ml-auto">
          <h5>Voucher Remark:</h5>
          <textarea name="comments" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
            v-model="invoiceConfig.voucher_remark" maxlength="40" class="w-100 rounded border mt-2 mb-2"
            rows="5"></textarea>
        </b-col>
        <b-col lg="4" class="mt-4 col-12 ml-auto">
          <h5>Footnote:</h5>
          <textarea name="comments" maxlength="2000" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
            v-model="invoiceConfig.footnote" class="w-100 rounded border mt-2 mb-2" rows="5"></textarea>
        </b-col>
        <b-col lg="4" class="mt-4 col-12 ml-auto">
          <h5>Remark:</h5>
          <textarea name="comments" maxlength="120" :readonly="[2, 3, 4, 5, -5].includes(invoice.qbyte_status.code)"
            v-model="invoiceConfig.invoice_remark" class="w-100 rounded border mt-2 mb-2" rows="5"></textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-modal id="add-qbyte-comments" :no-close-on-backdrop="true" title="Add Comment" size="xl"
          @ok="switchAction()">
          <h5>Please add a comment.</h5>
          <textarea name="comments" v-model="comments" class="w-100 rounded border mt-2" rows="3"></textarea>
          <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="success" :disabled="comments.trim().length === 0" @click="ok()">
              OK
            </b-button>
            <b-button size="sm" variant="danger" @click="cancel()">
              Cancel
            </b-button>
          </template>
          <i class="fa fa-exclamation-triangle" v-show="comments.trim().length === 0">Comment is require.</i>
        </b-modal>
        <b-col lg="8" class="mt-4 col-12 ml-auto" style="display:flex; justify-content: flex-end;"
          v-show="invoiceConfigLoading">
          <button type="submit" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"
            :disabled="!invoiceConfig.isValid" @click="submitInvoice(0)" class="btn btn-success mr-2 mt-3 mb-3"><i
              class="fa fa-check"></i> Submit</button>
          <button type="submit" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"
            :disabled="!invoiceConfig.isValid" @click="submitInvoice(1)" class="btn btn-blue mt-3 mb-3 mr-2"><i
              class="fa fa-save"></i> Save As Draft</button>
          <button type="submit" v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"
            :disabled="!invoiceConfig.isValid" @click="submitInvoice(2)" class="btn btn-warning mt-3 mb-3 mr-2"
            style="color: white;"><i class="fa fa-edit"></i> Save Changes</button>
          <b-dropdown class="mt-3 mb-3 mr-2" variant="primary" text="Send Back To"
            v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)">
            <b-dropdown-item v-b-modal.add-qbyte-comments @click="submissionAction = 0">Well Site Supervisor
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.add-qbyte-comments @click="submissionAction = 1">Project Manager
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.add-qbyte-comments @click="submissionAction = 2">Manager</b-dropdown-item>
          </b-dropdown>
          <button class="btn btn-danger mr-2 mt-3 mb-3" v-b-modal.add-qbyte-comments @click="submissionAction = 3"
            v-if="[0, 1, -1, -2, -3].includes(invoice.qbyte_status.code)"><i class="fa fa-times-circle"></i>
            Reject</button>
          <button class="btn btn-danger mr-2 mt-3 mb-3" @click="updateInvoiceStatus(-5)"
            v-if="[2, 3].includes(invoice.qbyte_status.code)"><i class="fa fa-trash"></i> Delete Invoice</button>
          <button class="btn btn-secondary mr-2 mt-3 mb-3" @click="updateInvoiceStatus(0)"
            v-if="[2, 3].includes(invoice.qbyte_status.code)"><i class="fa fa-question"></i> Unprocess Invoice</button>
        </b-col>
      </b-row>
      <b-row v-if="invoiceConfigLoading">
        <b-col lg="8" class="mt-4 col-12 ml-auto"
          style="display:flex; justify-content: flex-end; color: red; cursor: pointer;" v-show="!invoiceConfig.isValid">
          <span v-b-tooltip.hover title="This invoice has incorrect coding percentage or contains no codings"> <i
              class="fa fa-exclamation-triangle mr-2"></i> Invoice Coding is Incorrect.</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="8" class="mt-4 col-12 ml-auto" style="display:flex; justify-content: flex-end;"
          v-show="invoiceConfigLoading">
          <div v-show="invoiceError" class="d-block">
            <i v-show="invoiceError" class="fa fa-exclamation-triangle"></i><span v-show="invoiceError"
              class="help">Invoice Error. Please fix invoice or contact admin.</span>
          </div>
          <div v-if="errorMessage !== ''" class="d-block" style="color: red;">
            <i class="fa fa-exclamation-triangle"></i><span class="help">{{ errorMessage }}</span><br />
            <span v-if="errorMessage === 'Invoice Not Found.'">Invoice is recently edited. Please reload the invoice for
              submission.</span>
          </div>
        </b-col>
      </b-row>
      <b-row v-show="invoiceConfigLoading" v-if="recent_submission != null">
        <b-col lg="12">
          <h2>Qbyte Response:</h2>
          <hr>
          <h5>Submitted At: {{ recent_submission.submitted_at | moment("MM/DD/YYYY hh:mm:ss A") }}</h5>
          <h5 v-if="recent_submission.status.text == 'loaded'">Loaded At: {{ recent_submission.loaded_at |
              moment("MM/DD/YYYY hh:mm:ss A")
          }}</h5>
          <h5
            v-if="recent_submission.status.text == 'posted' || (recent_submission.status.text == 'failed' && !Array.isArray(recent_submission.errors[0])) || recent_submission.status.text == 'submitted'">
            Report ID: {{ recent_submission.report_id }}</h5>
          <h5 v-if="recent_submission.status.text == 'posted'">Posted At: {{ recent_submission.posted_at |
              moment("MM/DD/YYYY hh:mm:ss A")
          }}</h5>
          <h5 v-if="recent_submission.status.text == 'processed'">Processed At: {{ recent_submission.processed_at |
              moment("MM/DD/YYYY hh:mm:ss A")
          }}</h5>
          <h5 v-if="recent_submission.archived_at">Archived At: {{ recent_submission.archived_at | moment("MM/DD/YYYY hh: mm: ss A")}}</h5>
          <div v-if="recent_submission.voucher">
            <h5>Voucher Info:</h5>
            <ul>
              <li v-if="recent_submission.voucher.invoice_id">Invoice ID: {{ recent_submission.voucher.invoice_id }}
              </li>
              <li v-if="recent_submission.voucher.payment_status">Payment Status:
                {{ recent_submission.voucher.payment_status }}</li>
              <li v-if="recent_submission.voucher.voucher_id">Voucher ID: {{ recent_submission.voucher.voucher_id }}
              </li>
              <li v-if="recent_submission.voucher.voucher_num">Voucher Number: {{ recent_submission.voucher.voucher_num
              }}
              </li>
              <li v-if="recent_submission.voucher.voucher_status">Voucher Status:
                {{ recent_submission.voucher.voucher_status }}</li>
            </ul>
          </div>

          <div v-if="recent_submission.errors && Array.isArray(recent_submission.errors[0])">
            <h5>Qbyte Validation: </h5>
            <table id="submission-msg" class="mt-2">
              <thead>
                <tr>
                  <th>QBYTE TABLE NAME</th>
                  <th>INVOICE ID</th>
                  <th>VOUCHER ID</th>
                  <th>CREATE USER</th>
                  <th>CREATE DATE</th>
                  <th>INVOICE AMOUNT</th>
                </tr>
              </thead>
              <tbody v-for="val in recent_submission.errors[0]">
                <tr>
                  <td>{{ 'TEMP_INVC_NUM' in val ? 'TEMP_INVOICES' : 'INVOICES' }}</td>
                  <td>{{ val.INVC_ID }}</td>
                  <td>{{ val.VOUCHER_ID }}</td>
                  <td>{{ val.CREATE_USER }}</td>
                  <td>{{ val.CREATE_DATE | moment("MM/DD/YYYY hh:mm:ss A") }}</td>
                  <td>{{ val.INVC_AMT }}</td>
                </tr>
              </tbody>
            </table>
            <span style="color: red;" class="mt-2">Above invoices already exist in qbyte.</span>
          </div>
          <div v-else-if="recent_submission.errors">
            <h5>Failed At: {{ recent_submission.failed_at | moment("MM/DD/YYYY hh:mm:ss A") }}</h5>
            <h5>Errors:</h5>
            <ul style="color: #b54040;">
              <li v-for="error in recent_submission.errors">
                {{ error }}
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <hr />
      <InvoiceHistory v-show="invoiceConfigLoading" v-bind:history="invoice"></InvoiceHistory>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import VendorDetails from "./../invoice/InvoiceVendorDetails";
import CompanyDetails from "./../invoice/InvoiceCompanyDetails";
import InvoiceDetails from "./../invoice/InvoiceDetails";
import InvoiceHeader from "./../invoice/InvoiceHeader";
import InvoiceServices from "./../invoice/InvoiceServices";
import InvoiceAfes from "./../invoice/InvoiceAfes";
import InvoiceTotals from "./../invoice/InvoiceTotals";
import InvoiceHistory from "./../invoice/InvoiceHistory";
import MasterInvoice from "./../../components/MasterInvoice";
import AccountantInvoice from "./../../views/accountant/AccountantInvoice";
import ManagerInvoice from "./../../views/manager/ManagerInvoice";
import moment from "moment";
import { RotateSquare2 } from "vue-loading-spinner";

export default {
  name: "AccountantInvoice",
  components: {
    vSelect,
    Datepicker,
    InvoiceHeader,
    VendorDetails,
    InvoiceServices,
    InvoiceAfes,
    CompanyDetails,
    InvoiceDetails,
    InvoiceTotals,
    InvoiceHistory,
    RotateSquare2,
    "Master-Invoice": MasterInvoice,
    "ACC-Invoice": AccountantInvoice,
    "MAN-Invoice": ManagerInvoice,
  },
  data() {
    return {
      showModal: false,
      Vendor_full_name: "",
      deleted_service: [],
      observer: new IntersectionObserver(this.infiniteScroll),
      limit: 5000,
      search: "",
      taxes: [],
      uom_array: [
        "L",
        "gal",
        "bbl",
        "m3",
        "Mb",
        "MMb",
        "Mcf",
        "MMcf",
        "Bcf",
        "Tcf",
        "Btu",
        "MMBtu",
        "cf",
      ],
      uom_options: [
        { value: "L", text: "Litres" },
        { value: "gal", text: "Gallons" },
        { value: "bbl", text: "Barrels" },
        { value: "m3", text: "cubic metre" },
        { value: "cf", text: "cubic feet" },
        { value: "Mb", text: "thousand barrels" },
        { value: "MMb", text: "million barrels" },
        { value: "Mcf", text: "thousand cubic feet" },
        { value: "MMcf", text: "million cubic feet" },
        { value: "Bcf", text: "billion cubic feet" },
        { value: "Tcf", text: "trillion cubic feet" },
        { value: "Btu", text: "British thermal units" },
        { value: "MMBtu", text: "million British thermal units" },
      ],
      volume_options: [
        { value: "L", text: "Litres" },
        { value: "gal", text: "Gallons" },
        { value: "bbl", text: "Barrels" },
        { value: "m3", text: "cubic metre" },
        { value: "cf", text: "cubic feet" },
      ],

      uom_conversion: [
        { value: "L", text: "Litres" },
        { value: "gal", text: "Gallons" },
        { value: "bbl", text: "Barrels" },
        { value: "m3", text: "cubic metre" },
        { value: "cf", text: "cubic feet" },
        { value: "Mb", text: "thousand barrels" },
        { value: "MMb", text: "million barrels" },
        { value: "Mcf", text: "thousand cubic feet" },
        { value: "MMcf", text: "million cubic feet" },
        { value: "Bcf", text: "billion cubic feet" },
        { value: "Tcf", text: "trillion cubic feet" },
        { value: "Btu", text: "British thermal units" },
        { value: "MMBtu", text: "million British thermal units" },
      ],
      volume_options: [
        { value: "L", text: "Litres" },
        { value: "gal", text: "Gallons" },
        { value: "bbl", text: "Barrels" },
        { value: "m3", text: "cubic metre" },
        { value: "cf", text: "cubic feet" },
      ],
      default_uom: null,
      chequeDate: "",
      chequeNumber: "",
      invoice: {
        qbyte_status: {
          code: 0,
          text: "Draft",
        },
      },
      today: new Date(),
      uom: [
        { value: "L", label: "L" },
        { value: "KG", label: "KG" },
      ],
      vendorDetails: [],
      invoiceConfigLoading: false,
      serviceNames: [
        { value: "Drilling", label: "Drilling", code: "9294" },
        { value: "Water", label: "Water", code: "9594" },
      ],
      allafe: [
        { value: "34353", label: "34353" },
        { value: "65434", label: "65434" },
      ],
      service: {
        startdate: "",
        enddate: "",
        quantity: 1,
        price: 3.99,
        discount: 0,
      },
      formats: {
        title: "MMMM YYYY",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "MM/DD/YYYY", "YYYY/MM/DD"], // Only for `v-date-picker`
        dayPopover: "L", // Only for `v-date-picker`
      },
      addAfeButtons: 1,
      addAfeShow: 0,
      afe: [],
      afes: [],
      percent: 0,
      vwref: [],
      afeData: [],
      gst: 5,
      quickpay: 0,
      services: [],
      serviceData: [],
      comments: "",
      invoiceError: false,
      tax_percentage: 0,
      deleteAps: [],
      serviceChainData: [],
      uwiData: [],
      cc1Data: [],
      cc2Data: [],
      cc3Data: [],
      company_name: "",
      vendor_service_data: [],
      uom_volume: 0,
      remote_afe_data: [],
      cc1: [],
      cc2: [],
      cc3: [],
      remote_afe_obj: { value_1: "" },
      highestAfe: [],
      netDays: 0,
      currency: "",
      usersName: "",
      parameterList: null,
      organizationId: null,
      invoiceConfig: {
        taxes: null,
        chains: [],
        business_associate: {},
        quickpay: {},
      },
      accountant_ccodeOptions: [],
      org_idOptions: [],
      invoice_typeOptions: [],
      pay_codeOptions: [],
      invoice_periodOptions: [],
      afePeriodOptions: [],
      workpathOptions: [],
      purchase_numOptions: [],
      invoice_afeOptions: [],
      invoice_costCenterOptions: [],
      profile: [],
      discount_percentage: 0,
      discount_amount: 0,
      invoice_businessAssociateOptions: [],
      recent_submission: null,
      afeSubtotal: [],
      updatedSubtotal: 0,
      discount_amount: 0,
      invoice_vendor_mail: "",
      isEditInvoice: true,
      oldVin: "",
      errorMessage: "",
      submissionAction: 0
    };
  },
  methods: {
    switchAction() {
      if (this.comments.trim().length == "") {
        return;
      }
      switch (this.submissionAction) {
        case 0: return this.kickBack(0, 'Well Site Supervisor');
        case 1: return this.kickBack(8, 'Project Manager');
        case 2: return this.kickBack(6, 'Manager');
        case 3: return this.rejectInvoice(2);
      }
    },
    toggleSubmit(value) {
      this.showModal = value;
    },
    submitAfeData() {
      if (this.showModal) {
        this.$router.push("/accountant/configuration");
        this.$toasted.show("Invoice Edited Successfully.", {
          type: "success",
          duration: "5000",
        });
      }
    },
    isQuickPay() {
      this.invoiceConfig.is_quickpay = !this.invoiceConfig.is_quickpay;
    },
    updateTax() {
      this.taxes.map((u) => {
        u.subtotal = this.subtotal;
        u.amount = (u.percentage / 100) * u.subtotal;
        u.quickpay_subtotal = this.quickpay_subtotal;
        u.quickpay_amount = (u.percentage / 100) * this.quickpay_subtotal;
      });
    },
    changeDiscount(subtotal) {
      this.discount_amount = subtotal * (this.discount_percentage / 100);
      this.updateTax();
    },
    changeAmount(subtotal) {
      this.discount_percentage = (this.discount_amount * 100) / subtotal;
      this.updateTax();
    },
    changeSubtotal(subtotal, index) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.afeSubtotal[index].subtotal = subtotal;
      this.invoiceConfig.chains[index].user = {
        user_name: this.usersName,
        date: date,
        time: time,
        editedSubtotal: subtotal,
      };
      this.invoiceConfig.chains[index].quickpay_subtotal =
        (subtotal * (100 - this.invoiceConfig.quickpay_percentage)) / 100;
      this.invoiceConfig.quickpay.quickpay_subtotal = this.quickpay_subtotal;
      this.invoiceConfig.chains[index].user.editedQuickPaySubtotal =
        this.invoiceConfig.chains[index].quickpay_subtotal;
      this.updateTax();
      this.invoiceConfig.subtotal = this.subtotal;
      this.discount_amount = 0;
      this.discount_percentage = 0;
    },
    editVendorInvoiceNumber() {
      const obj = {
        vid: this.invoice.vid,
        vin: this.invoiceConfig.vendor_invoice_num,
      };
      this.$axios
        .post(
          "vendor/edit/invoice/invoice_number/invoiceid/" +
          this.invoice.invoiceid,
          obj
        )
        .then((res) => {
          if (res.data.status === 1) {
            this.invoice.vendor_invoice_number =
              this.invoiceConfig.vendor_invoice_num;
            this.invoiceConfig.voucher_remark = (
              this.invoiceConfig.vendor_invoice_num + this.Vendor_full_name
            ).substring(0, 40);
            this.isEditInvoice = !this.isEditInvoice;
            this.oldVin = this.invoiceConfig.vendor_invoice_num;
            this.$toasted.show("Vendor Invoice Number Edited.", {
              type: "success",
              duration: "3000",
            });
          } else {
            this.$toasted.show(`${res.data.msg}`, {
              type: "error",
              duration: "3000",
            });
          }
        })
        .catch((err) => {
          this.$toasted.show(`Error Edit Vendor Invoice Number.`, {
            type: "error",
            duration: "3000",
          });
        });
    },
    kickBack(pass, passed_to) {
      var comments =
        this.usersName + " ($" + this.formatPrice(this.invoice.total) + ")";
      if (this.comments != "") {
        comments += ": " + this.comments;
      }
      let data = JSON.stringify({
        invnum: this.invoice.invnum,
        inv_pass: pass,
      });
      this.$http
        .get("/wts/remove/qbyte_invoice/" + this.invoice.invoiceid)
        .then((res) => {
          if (res.data.status != null) {
            this.$http
              .post("/wts/edit/invpass_kickback", data)
              .then(async () => {
                this.$toasted.show("Invoice Sent Back Successfully", {
                  type: "success",
                  duration: "3000",
                });
                const history_data = this.assignHistoryData(3, passed_to);
                await this.createInvoiceHistory(history_data);
                this.$router.push("/accountant/configuration");
              })
              .catch(() => { });
          } else {
            this.$toasted.show("Invoice Is Already Processed in qbyte", {
              type: "error",
              duration: "5000",
            });
          }
        })
        .catch(() => {
          this.$toasted.show("Error", { type: "error", duration: "5000" });
        });
    },
    assignHistoryData(status, passed_to) {
      return {
        role: `${this.profile.authority !== 6 ? "Manager" : "Accountant"}`,
        role_id: null,
        userName: this.usersName,
        comments: this.comments,
        action_desc: status === 2 ? 'Rejected' : status === 3 ?
          `Send Back To ${passed_to}` : 'Approved',
        action_status: status === 2 ? -1 : status === 3 ? 4 : 5, // -1 for rejected, 4 for send back to, 5 for approval 
        invoiceData: {
          invoiceid: this.invoice.invoiceid,
          companyid: this.invoice.companyid,
          projectid: this.invoice.projectid,
          vid: this.invoice.vid,
          previousInvoiceStatus: this.invoice.inv_passed,
          projectname: this.vwref.projectname,
          msg: `Edited By ${this.profile.authority !== 6 ? "Manager" : "Accountant"}`,
        }
      }
    },
    setTheInvoice(props, setInvoice) {
      if (setInvoice == 1) {
        this.setInvoice(props, setInvoice, this.$refs.invoicetable);
        this.$router.push({
          name: "Accountant Invoices",
          path: "/accountant/invoices",
          params: { invoice: props, set: setInvoice, role: "Accountant" },
        });
      } else {
        this.setInvoice(props, setInvoice, this.$refs.invoicetable);
        this.$router.push({
          name: "Accountant Invoice",
          path: "/accountant/invoice",
          params: { invoice: props, set: setInvoice },
        });
      }
    },
    setTaxPercent(line, amount, subtotal, index, qp_subtotal) {
      this.taxes[index].percentage = (amount / subtotal) * 100;
      this.taxes[index].quickpay_amount =
        (this.taxes[index].percentage / 100) * qp_subtotal;
      this.assignHistory(index);
    },
    setAmount(line, percentage, subtotal, index, qp_subtotal) {
      this.taxes[index].amount = (percentage / 100) * subtotal;
      this.taxes[index].quickpay_amount = (percentage / 100) * qp_subtotal;
      this.assignHistory(index);
    },
    assignHistory(index) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      this.invoiceConfig.edited_invoice_history = {
        user_name: this.usersName,
        date: date,
        time: time,
        edited_tax_amount: this.taxes[index].amount,
        edited_qp_amount: this.taxes[index].quickpay_amount,
        email: this.profile.email,
      };
    },
    clearAfe(index) {
      var chains = JSON.parse(JSON.stringify(this.invoiceConfig.chains));
      chains[index].remote_afe = null;
      console.log(chains[index].remote_afe);
      this.setChains(chains);
    },
    setChains(chains) {
      Vue.set(this.invoiceConfig, "chains", chains);
    },
    async updateInvoiceStatus(status) {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      const time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const userData = {
        usersName: this.usersName,
        date: date,
        time: time,
        userEmail: this.profile.email,
        previousStatus: this.invoice.qbyte_status.code,
        updatedStatus: status,
      };
      if (this.invoiceConfig.edited_invoice_history) {
        this.invoiceConfig.edited_invoice_history = {
          ...JSON.parse(this.invoiceConfig.edited_invoice_history),
          UpdateStatus: userData,
        };
      } else {
        this.invoiceConfig.edited_invoice_history = userData;
      }

      try {
        await this.$http.put(
          "/accountant/qbyte/invoice_config/update/" +
          status +
          "/" +
          this.invoice.invoiceid,
          this.invoiceConfig.edited_invoice_history
        );
        this.$toasted.show(
          `Invoice ${status === 0 ? "Updated" : "Deleted"} Successfully`,
          { type: "success", duration: "3000" }
        );
        this.$router.push("/accountant/configuration");
      } catch (err) {
        this.$toasted.show(
          ` Failed to ${status === 0 ? "Update" : "Delete"} Invoice`,
          { type: "error", duration: "5000" }
        );
      }
    },
    rejectInvoice(status) {
      var comments = this.usersName;
      if (this.comments != "") {
        comments += ": " + this.comments;
      }

      let data = JSON.stringify({
        invoiceid: this.invoice.invoiceid,
        projectid: this.invoice.projectid,
        ac_note: comments,
        wts_stat: status,
        email: this.serviceData[0].email,
        comments: comments,
        usersName: this.usersName,
        invnum: this.invoice.invnum,
        fname: this.invoice.vfname,
        lname: this.invoice.vlname,
      });
      this.$http
        .get("/wts/remove/qbyte_invoice/" + this.invoice.invoiceid)
        .then((res) => {
          if (res.data.status != null) {
            this.$http
              .post("/accountant/edit/invoice", data)
              .then(async (response) => {
                const history_data = this.assignHistoryData(status);
                if ('errorMsg' in response.data && response.data.errorMsg != null) history_data['invoiceData']['rejectionError'] = response.data.errorMsg;
                await this.createInvoiceHistory(history_data);
                this.$toasted.show("Invoice Updated Successfully", {
                  type: "success",
                  duration: "3000",
                });
                this.$router.push("/accountant/configuration");
              })
              .catch((error) => {
                this.invoiceError = true;
              });
          } else {
            this.$toasted.show("Invoice Is Already Processed in qbyte", {
              type: "error",
              duration: "5000",
            });
          }
        })
        .catch(() => {
          this.$toasted.show("Error", { type: "error", duration: "5000" });
        });
    },
    submitInvoice(status) {
      this.errorMessage = "";
      this.vendor_service_data.forEach((v, i) => {
        if (
          !this.uom_array.includes(v.uom) ||
          !this.uom_conversion.includes(v.qbyte_uom)
        )
          return;
      });

      var comments = this.usersName;
      if (this.comments != "") {
        comments += ": " + this.comments;
      }


      var chains = [];

      if (
        this.invoiceConfig.accounting_period == null &&
        status != 1 &&
        status != 2
      ) {
        this.$toasted.show("Accounting Period Empty", {
          type: "error",
          duration: "3000",
        });
      }

      if (this.invoiceConfig.business_associate === null) {
        this.$toasted.show("Business Associate is Incomplete", {
          type: "error",
          duration: "3000",
        });
        return;
      }
      if (this.invoiceConfig.vendor_invoice_num.length > 20) {
        this.errorMessage =
          "Vendor Invoice Number excceeds word limit of 20 characters.";
        return;
      }

      for (var i = 0; i < this.invoiceConfig.chains.length; i++) {
        if (
          this.invoiceConfig.chains[i].org == null &&
          status != 1 &&
          status != 2
        ) {
          this.$toasted.show("AFE Organization ID Incomplete", {
            type: "error",
            duration: "3000",
          });
        }
        if (
          this.invoiceConfig.chains[i].remote_cost_center == null &&
          status != 1 &&
          status != 2
        ) {
          this.$toasted.show("AFE Remote Cost Center Incomplete", {
            type: "error",
            duration: "3000",
          });
        }

        let chainData = {
          id: this.invoiceConfig.chains[i].id,
          remark: this.invoiceConfig.chains[i].remark,
          remote_cost_center:
            this.invoiceConfig.chains[i].remote_cost_center.id,
          org: this.invoiceConfig.chains[i].org.id,
          account: this.invoiceConfig.chains[i].account.id,
          subtotal: this.invoiceConfig.chains[i].subtotal,
        };

        if (this.invoiceConfig.chains[i].remote_afe != null) {
          chainData.remote_afe = this.invoiceConfig.chains[i].remote_afe.id;
        } else {
          chainData.remote_afe = null;
        }

        if (this.invoiceConfig.chains[i].activity_period != null) {
          chainData.activity_period =
            this.invoiceConfig.chains[i].activity_period.id;
        } else {
          chainData.activity_period = null;
        }
        if (this.invoiceConfig.chains[i].user !== undefined) {
          let user_info =
            this.invoiceConfig.chains[i].user_info != null
              ? JSON.parse(this.invoiceConfig.chains[i].user_info)
              : null;
          chainData.user =
            user_info != null
              ? [...user_info, this.invoiceConfig.chains[i].user]
              : [this.invoiceConfig.chains[i].user];
        }

        if (this.invoiceConfig.is_quickpay) {
          chainData.quickpay_subtotal =
            this.invoiceConfig.chains[i].quickpay_subtotal;
        }

        chains.push(chainData);
      }

      if (
        this.invoiceConfig.exchange_rate == "" &&
        status != 1 &&
        status != 2
      ) {
        this.$toasted.show("Exchange Rate Must Not Be Empty", {
          type: "error",
          duration: "3000",
        });
      }

      var taxes = [];

      for (var i = 0; i < this.taxes.length; i++) {
        if (this.taxes[i].name == "" || this.taxes[i].percentage == "") {
          this.$toasted.show("Tax Table Fields Incomplete", {
            type: "error",
            duration: "3000",
          });
        }

        taxes.push({
          name: this.taxes[i].name,
          percentage: parseFloat(this.taxes[i].percentage),
          account: this.taxes[i].account.id,
          amount: this.taxes[i].amount,
          qp_amount: this.invoiceConfig.is_quickpay
            ? this.taxes[i].quickpay_amount
            : 0,
        });
      }

      let data = {};

      data.action = "submit";
      if (status == 1) {
        data.action = "draft";
      }
      if (status == 2) {
        data.action = "save";
      }

      data.project_id = this.invoice.projectid;
      data.invoice_id = this.invoice.invoiceid;

      //INVOICE CONFIGURATION
      data.config = {};

      data.config.org = this.invoiceConfig.org.id;
      data.config.invoice_type = this.invoiceConfig.invoice_type.id;
      data.config.accounting_period = this.invoiceConfig.accounting_period.id;
      data.config.business_associate = this.invoiceConfig.business_associate.id;
      data.config.is_quickpay = this.invoiceConfig.is_quickpay;
      data.config.invoice_date = this.$moment(
        this.invoiceConfig.invoice_date
      ).format("YYYY-MM-DD");

      if (data.config.invoice_date == "Invalid date") {
        this.$toasted.show("Invalid Date", { type: "error", duration: "3000" });
        return;
      }

      if (this.invoiceConfig.pay_code != null) {
        data.config.pay_code = this.invoiceConfig.pay_code.id;
      }

      if (this.invoiceConfig.purchase_num != null) {
        data.config.purchase_num = this.invoiceConfig.purchase_num.id;
      }

      data.config.exchange_rate = this.invoiceConfig.exchange_rate;
      if (
        this.invoiceConfig.is_separate_cheque == null ||
        !this.invoiceConfig.is_separate_cheque
      ) {
        data.config.is_separate_cheque = false;
      } else {
        data.config.is_separate_cheque = true;
      }

      var invoiceDate = this.$moment(this.invoice.creation_date);

      if (
        "hold_date" in this.invoiceConfig &&
        this.invoiceConfig.hold_date != "" &&
        this.invoiceConfig.hold_date != null &&
        this.invoiceConfig.hold_date != undefined
      ) {
        if (!this.$moment(this.invoiceConfig.hold_date).isAfter(invoiceDate)) {
          this.$toasted.show("Hold Date Must Be After Invoice Date.", {
            type: "error",
            duration: "3000",
          });
          return;
        }
        data.config.hold_date = this.$moment(
          this.invoiceConfig.hold_date
        ).toISOString();
      }
      if (
        "due_date" in this.invoiceConfig &&
        this.invoiceConfig.due_date != "" &&
        this.invoiceConfig.due_date != null &&
        this.invoiceConfig.due_date != undefined
      ) {
        if (!this.$moment(this.invoiceConfig.due_date).isAfter(invoiceDate)) {
          this.$toasted.show("Due Date Must Be After Invoice Date.", {
            type: "error",
            duration: "3000",
          });
          return;
        }
        data.config.due_date = this.$moment(
          this.invoiceConfig.due_date
        ).toISOString();
      }

      if (
        this.discount_percentage != "" &&
        this.discount_percentage != undefined &&
        this.discount_percentage != null
      ) {
        data.config.discount_percentage = Number(this.discount_percentage);
      }

      if (
        this.invoiceConfig.invoice_remark != "" &&
        this.invoiceConfig.invoice_remark != undefined &&
        this.invoiceConfig.invoice_remark != null
      ) {
      } else {
        data.config.invoice_remark = "";
      }

      data.config.invoice_remark = this.invoiceConfig.invoice_remark;
      data.config.footnote = this.invoiceConfig.footnote;
      data.config.voucher_remark = this.invoiceConfig.voucher_remark;
      data.config.exchange_rate = this.invoiceConfig.exchange_rate;
      data.config.edited_invoice_history =
        this.invoiceConfig.edited_invoice_history;

      data.chains = chains;
      data.taxes = taxes;
      data.deleted_vendor_service_data = this.deleted_service;
      data.vendor_service_data = this.vendor_service_data;
      data.remote_afe_data = this.remote_afe_data;
      let volume = [];

      this.vendor_service_data.map((v, i) => {
        v.qbyte_quantity == null
          ? volume.push(Number(v.quantity))
          : volume.push(Number(v.qbyte_quantity));
        this.vendor_service_data[i].afe_volume =
          v.qbyte_quantity == null
            ? Number(v.quantity)
            : Number(v.qbyte_quantity);
      });

      data.volume = this.lodash.sum(volume);
      data.originalSubtotal = this.invoiceConfig.subtotal;
      if (this.invoiceConfig.is_quickpay) {
        data.originalQuickPaySubtotal =
          this.invoiceConfig.quickpay.quickpay_subtotal;
      }
      data.is_quickpay = this.invoiceConfig.is_quickpay;
      data.id = this.invoiceConfig.id;
      data.accountant_name = this.usersName;
      this.$http
        .post("/accountant/qbyte/invoice_config", data)
        .then((response) => {
          if (response.status === 201) {
            this.$toasted.show(`${response.data.msg}`, {
              type: "error",
              duration: "3000",
            });
            this.errorMessage = response.data.msg;
            return;
          }
          this.$toasted.show("Invoice Updated Successfully", {
            type: "success",
            duration: "3000",
          });
          if (status !== 2) {
            this.$router.push("/accountant/configuration");
          }
        })
        .catch((error) => {
          this.invoiceError = true;
        });
    },
    loadData() {
      var pid = this.invoice.projectid;
      var invid = this.invoice.invoiceid;

      this.$http
        .get("/manager/get/invoice_services/" + pid + "/" + invid)
        .then((response) => {
          this.serviceData = this.lodash.uniqBy(response.data.result, "vsid");
          this.currency = this.serviceData[0].currency;
          const that = this;
          this.afes = response.data.result.map(function (afe) {
            afe.index = that.serviceData.findIndex((x) => x.vsid == afe.vsid);
            return afe;
          });

          this.afes = this.lodash.uniqBy(this.afes, "ccthree_code");

          if (this.afes) {
            var res = Math.max.apply(
              Math,
              response.data.result.map(function (o) {
                return o.percentage;
              })
            );
            this.highestAfe = response.data.result.find(function (o) {
              return o.percentage == res;
            });
          }
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
      this.$http
        .get("/manager/get/profile/")
        .then((response) => {
          this.profile = response.data;
          this.company_name = response.data.company;
          this.usersName = response.data.fname + " " + response.data.lname;
        })
        .catch((error) => { });
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
  },
  computed: {
    chains: function () {
      const chains = this.invoiceConfig.chains;
      return {
        ...chains,
      };
    },
    subtotal: function () {
      const that = this;
      return this.afeSubtotal.reduce(function (total, item) {
        let subtotal = Number(item.subtotal).toFixed(2);
        let discount_percentage =
          that.discount_percentage != "" ? Number(that.discount_percentage) : 0;
        if (that.discount_percentage != "") {
          return (
            total +
            parseFloat(subtotal) -
            parseFloat(subtotal) * (discount_percentage / 100)
          );
        } else {
          return total + parseFloat(subtotal);
        }
      }, 0);
    },

    quickpay_subtotal: function () {
      const that = this;
      return that.invoiceConfig.chains.reduce(function (total, item) {
        let quickpay_subtotal = Number(item.quickpay_subtotal).toFixed(2);
        let discount_percentage =
          that.discount_percentage != "" ? Number(that.discount_percentage) : 0;
        console.log(discount_percentage, quickpay_subtotal, "subtotal");
        if (that.discount_percentage != "") {
          return (
            total +
            parseFloat(quickpay_subtotal) -
            parseFloat(quickpay_subtotal) * (discount_percentage / 100)
          );
        } else {
          return total + parseFloat(quickpay_subtotal);
        }
      }, 0);
    },
    total: function () {
      return this.subtotal + this.tax;
    },
    tax: function () {
      var taxes = this.taxes.reduce(function (total, item) {
        // return total + parseFloat((item.percentage / 100) * that.subtotal);
        console.log(total, item, ":::")
        return total + parseFloat(item.amount);
      }, 0);
      return taxes;
    },
    quickpay_tax: function () {
      var taxes = this.taxes.reduce(function (total, item) {
        //  return total + parseFloat((item.percentage / 100) * that.quickpay_subtotal);
        return total + parseFloat(item.quickpay_amount);
      }, 0);

      return taxes;
    },
    filtered() {
      if (this.parameterList != null) {
        var index = this.parameterList.findIndex((x) => x.name == "account");

        return this.parameterList[index].values.filter((x) =>
          x.line.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        return [];
      }
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  mounted() {
    if (!this.$route.params.invoice) {
      this.invoice = this.$session.get("invoice");
    } else {
      this.invoice = this.$route.params.invoice;
    }

    // if (this.invoice.accountant_paid === 1) {
    //   this.invoice.qbyte_status.code = 2;
    // }
    this.$http.get("/accountant/qbyte/complex_fields").then((response) => {
      this.parameterList = response.data;
      this.$http
        .get(
          "/accountant/qbyte/invoice_config/" +
          this.invoice.projectid +
          "/" +
          this.invoice.invoiceid
        )
        .then((response) => {
          this.recent_submission = response.data.recent_submission;
          this.invoiceConfig = response.data.draft;
          if (this.invoiceConfig.is_quickpay == null) {
            this.invoiceConfig.is_quickpay = this.invoiceConfig.has_quickpay;
          }

          // fixed the voucher remark lenght upto 40;
          // setting up quickpay discount percentage;
          // this.discount_percentage = response.data.chains[0].discount_percentage;
          this.Vendor_full_name = this.invoice.vcn;
          this.invoiceConfig.voucher_remark = !this.invoiceConfig.voucher_remark
            ? (
              this.invoiceConfig.vendor_invoice_num + this.Vendor_full_name
            ).substring(0, 40)
            : this.invoiceConfig.voucher_remark;
          this.invoiceConfig.chains = response.data.chains;
          this.afeSubtotal = response.data.chains;
          this.oldVin = this.invoiceConfig.vendor_invoice_num;
          this.invoiceConfig.quickpay = {
            quickpay_percentage: response.data.draft.quickpay_percentage,
            quickpay_subtotal: response.data.draft.quickpay_subtotal,
            quickpay_date: response.data.draft.quickpay_date,
            quickpay_amount:
              response.data.draft.subtotal -
              response.data.draft.quickpay_subtotal,
          };
          var findIndex3 = this.parameterList.findIndex(
            (x) => x.name == "activity_period"
          );
          this.afePeriodOptions = this.parameterList[findIndex3].values;
          var findIndex = this.parameterList.findIndex(
            (x) => x.name == "account"
          );
          this.accountant_ccodeOptions = this.parameterList[findIndex].values;
          for (
            var i = 0;
            i < this.parameterList[findIndex].values.length;
            i++
          ) {
            var line = "";
            var cc1 = "";
            var cc2 = "";
            var cc3 = "";

            if (this.parameterList[findIndex].values[i].value_1 != null) {
              line += this.parameterList[findIndex].values[i].value_1;
              cc1 = this.parameterList[findIndex].values[i].value_1;
            }
            if (this.parameterList[findIndex].values[i].value_2 != null) {
              line += "-" + this.parameterList[findIndex].values[i].value_2;
              cc2 = this.parameterList[findIndex].values[i].value_2;
            }
            if (this.parameterList[findIndex].values[i].value_3 != null) {
              line += "-" + this.parameterList[findIndex].values[i].value_3;
              cc3 = this.parameterList[findIndex].values[i].value_3;
            }
            if (this.parameterList[findIndex].values[i].value_4 != null) {
              line += "-" + this.parameterList[findIndex].values[i].value_4;
            }
            this.parameterList[findIndex].values[i].line = line;

            this.invoiceConfig.chains.forEach((cc, j) => {
              if (
                cc.ccode_one == cc1 &&
                cc.ccode_two == cc2 &&
                cc.ccode_three == cc3 &&
                this.parameterList[findIndex].values[i].value_4 != "N"
              ) {
                this.serviceData.forEach((v, k) => {
                  if (
                    v.ccone_code == cc1 &&
                    v.cctwo_code == cc2 &&
                    v.ccthree_code == cc3 &&
                    v.volume != "N" &&
                    cc.afe_num == v.afenum
                  ) {
                    this.invoiceConfig.chains[j].li_vol =
                      this.parameterList[findIndex].values[i].value_4;
                    this.vendor_service_data.push({
                      name: v.name,
                      quantity: v.quantity,
                      uom: v.uom,
                      vsid: v.vsid,
                      qbyte_service_del_status: v.qbyte_service_del_status,
                      qbyte_uom: v.qbyte_uom,
                      qbyte_quantity: v.qbyte_quantity,
                      value_8: this.parameterList[findIndex].values[i].value_8,
                      prod_name: null,
                      cc1: cc1,
                      cc2: cc2,
                      cc3: cc3,
                      flag: "Y",
                      afe_volume: null,
                      afe_flag: this.parameterList[findIndex].values[i].value_5,
                      id: cc.id,
                    });
                  }
                });
              }

              if (
                cc.ccode_one == cc1 &&
                cc.ccode_two == cc2 &&
                cc.ccode_three == cc3
              ) {
                this.remote_afe_data.push({
                  afe_flag: this.parameterList[findIndex].values[i].value_5,
                  cc1: cc1,
                  cc2: cc2,
                  cc3: cc3,
                  id: cc.id,
                });
              }
            });
          }

          this.vendor_service_data = this.lodash.uniqBy(
            this.vendor_service_data,
            "vsid"
          );
          this.remote_afe_data = this.lodash.uniqBy(this.remote_afe_data, "id");
          var findIndex12 = this.parameterList.findIndex(
            (x) => x.name == "products"
          );
          for (
            var i = 0;
            i < this.parameterList[findIndex12].values.length;
            i++
          ) {
            this.vendor_service_data.forEach((v, j) => {
              if (
                this.parameterList[findIndex12].values[i].value_1 == v.value_8
              ) {
                this.vendor_service_data[j].prod_name =
                  this.parameterList[findIndex12].values[i].value_2;
                this.vendor_service_data[j].qbyte_uom =
                  this.parameterList[findIndex12].values[i].value_3;
                this.vendor_service_data[j].qbyte_quantity =
                  this.invoice.qbyte_status.code == 0
                    ? v.quantity
                    : v.qbyte_quantity;
                this.uom_conversion.push({
                  value: this.parameterList[findIndex12].values[i].value_3,
                  text: this.parameterList[findIndex12].values[i].value_2,
                });
              }
            });
          }

          this.uom_conversion = this.lodash.uniqBy(
            this.uom_conversion,
            "value"
          );

          var findIndex2 = this.parameterList.findIndex((x) => x.name == "org");
          this.org_idOptions = this.parameterList[findIndex2].values;

          var findIndex4 = this.parameterList.findIndex(
            (x) => x.name == "pay_code"
          );
          this.pay_codeOptions = this.parameterList[findIndex4].values;

          var findIndex5 = this.parameterList.findIndex(
            (x) => x.name == "invoice_type"
          );
          this.invoice_typeOptions = this.parameterList[findIndex5].values;

          var findIndex7 = this.parameterList.findIndex(
            (x) => x.name == "purchase_num"
          );
          this.purchase_numOptions = this.parameterList[findIndex7].values;

          var findIndex8 = this.parameterList.findIndex(
            (x) => x.name == "accounting_period"
          );

          for (
            var i = 0;
            i < this.parameterList[findIndex8].values.length;
            i++
          ) {
            var line = "";

            if (this.parameterList[findIndex8].values[i].value_1 != null) {
              line += this.parameterList[findIndex8].values[i].value_1;
            }
            if (this.parameterList[findIndex8].values[i].value_2 != null) {
              line +=
                "-" +
                this.$moment(
                  this.parameterList[findIndex8].values[i].value_2
                ).format("YY/MM");
            }
            if (this.parameterList[findIndex8].values[i].value_3 != null) {
              line += "-" + this.parameterList[findIndex8].values[i].value_3;
            }
            if (this.parameterList[findIndex8].values[i].value_4 != null) {
              line += "-" + this.parameterList[findIndex8].values[i].value_4;
            }
            this.parameterList[findIndex8].values[i].line = line;
          }
          this.invoice_periodOptions = this.parameterList[findIndex8].values;

          var findIndex9 = this.parameterList.findIndex(
            (x) => x.name == "business_associate"
          );
          this.invoice_businessAssociateOptions =
            this.parameterList[findIndex9].values;

          var findIndex10 = this.parameterList.findIndex(
            (x) => x.name == "afe"
          );
          this.invoice_afeOptions = this.parameterList[findIndex10].values;
          var findIndex11 = this.parameterList.findIndex(
            (x) => x.name == "cost_center"
          );
          this.invoice_costCenterOptions =
            this.parameterList[findIndex11].values;

          if (response.data.draft.due_date != null) {
            this.invoiceConfig.due_date = this.$moment(
              response.data.draft.due_date
            ).toDate();
          }
          if (response.data.draft.hold_date != null) {
            this.invoiceConfig.hold_date = this.$moment(
              response.data.draft.hold_date
            ).toDate();
          }

          this.taxes = response.data.taxes;

          //Unprocessed
          if (this.invoice.qbyte_status.code == 0) {
            for (var i = 0; i < response.data.chains.length; i++) {
              response.data.chains[i].activity_period =
                this.afePeriodOptions[0];
            }

            this.discount_percentage = 0;
            this.discount_amount = 0;

            for (var i = 0; i < this.taxes.length; i++) {
              if (this.taxes[i].account === null)
                this.taxes[i].account = this.parameterList[findIndex].values[0];
            }

            this.invoiceConfig.org = this.parameterList[findIndex2].values[0];
            this.invoiceConfig.invoice_type =
              this.parameterList[findIndex5].values[0];
            this.invoiceConfig.purchase_num =
              this.parameterList[findIndex7].values[0];
            this.invoiceConfig.accounting_period =
              this.parameterList[findIndex8].values[0];
            this.invoiceConfig.invoice_date = this.$moment(
              this.invoiceConfig.invoice_date
            ).format("MM/DD/YYYY");
            // No default value for pay_code. Set it to blank by default.
            // this.invoiceConfig.pay_code = this.parameterList[findIndex4].values[0];
          }

          if (this.invoice.qbyte_status.code != 0) {
            this.discount_percentage =
              response.data.draft.discount_percentage !== null
                ? response.data.draft.discount_percentage
                : 0;
            this.discount_amount =
              this.invoiceConfig.subtotal * (this.discount_percentage / 100);

            if (response.data.draft.due_date != null) {
              this.invoiceConfig.due_date = this.$moment(
                response.data.draft.due_date
              ).format("MM/DD/YYYY");
            }
            if (response.data.draft.hold_date != null) {
              this.invoiceConfig.hold_date = this.$moment(
                response.data.draft.hold_date
              ).format("MM/DD/YYYY");
            }
          }
          this.invoiceConfigLoading = true;
        });
    });
    this.invoiceID = this.invoice.invoiceid;
    this.quickpay = this.invoice.discount;
    this.gst = this.invoice.tax_percentage;
    var a = moment(this.invoice.endtday);
    var b = moment(this.invoice.creation_date);
    this.netDays = a.diff(b, "days");

    this.loadData();
  },
};
</script>


<style lang="scss">
.loading-gif {
  margin-top: 40px;
}

#taxes .vs--searchable {
  width: 450px;
}

.v-align-middle {

  td,
  tr {
    vertical-align: middle !important;
  }
}

.afenum {

  td,
  tr {
    vertical-align: middle !important;
  }
}

.v-select input {
  line-height: 1 !important;
}

.v-select.disabled .dropdown-toggle,
.v-select.disabled .dropdown-toggle .clear,
.v-select.disabled .dropdown-toggle input,
.v-select.disabled .open-indicator,
.v-select.disabled .selected-tag .close {
  background-color: #fff;
}

.v-select.disabled {
  .vs__actions {
    display: none !important;
  }
}

.v-select-overflow {
  .v-select {
    // .vs__selected-options {
    //   max-height: 29px !important;
    //   margin-top: 3px !important;
    //   overflow: hidden !important;
    // }
    // .selected-tag {
    //   display: inline !important;
    //
    //   span {
    //     display: inline !important;
    //   }
    // }
  }
}

// .v-select-wrapper {
//     display: inline;
//
//     .v-select {
//         .dropdown-toggle {
//             min-height: 40px;
//         }
//
//         .selected-tag {
//             overflow: hidden;
//             width: 80%;
//             text-overflow: ellipsis;
//             white-space: nowrap;
//         }
//
//         input[type=search] {
//             position: absolute;
//             width: 100%;
//             left: 0;
//             right: 0;
//         }
//
//         &.open input[type=search] {
//             width: auto !important;
//         }
//     }
// }

.error-match {
  color: #e21616;
  font-weight: bold;
  margin-left: 7px;
}

.vc-text-base {
  font-size: 14px;
  padding: 7px !important;
  color: #000;
}

.clear-allow {
  .dropdown-toggle .clear {
    display: inherit !important;
    margin-top: 5px;
    margin-right: 10px;
    font-weight: bold;
    font-size: 25px;
  }
}

.details-box {
  padding: 20px 10px 10px;

  div.row {
    margin-bottom: 10px;
  }

  .vs__dropdown-toggle {
    background-color: white;
  }

  .detail-box-input {
    margin-top: 5px;
    border-radius: 4px;
    padding: 5px;
    display: block;
    width: 100%;
    border: 1px solid #c4c4c4;
  }
}

#submission-msg {
  width: 100%;

  thead tr {
    background-color: #696969;
    color: white;
  }

  th,
  td {
    border: 1px solid grey;
    padding: 5px;
  }
}
</style>
